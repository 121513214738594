import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MetaTags = () => {
  const location = useLocation();

  useEffect(() => {
    // Create or select the meta tag
    let metaTag = document.querySelector('meta[name="robots"]');
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.name = 'robots';
      document.head.appendChild(metaTag);
    }

    // Set the content attribute to "noindex, nofollow"
    metaTag.content = 'noindex, nofollow';

    // Cleanup the meta tag when the component unmounts or route changes
    return () => {
      if (metaTag) {
        metaTag.remove();
      }
    };
  }, [location]);

  return null;
};

export default MetaTags;
