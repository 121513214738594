import React, { useEffect, useRef } from 'react';

const InfoDrawer = ({ onClose, isOpen, selectedVertical }) => {
  const drawerRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside of the InfoDrawer
    const handleClickOutside = (event) => {
      if (isOpen && drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose(); // Close the InfoDrawer if click is outside
      }
    };

    // Add event listener when the InfoDrawer is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !selectedVertical) {
    return null; // Don't render InfoDrawer if isOpen is false or selectedVertical is not found
  }

  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`} style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'auto' }}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card" style={{ maxWidth: '90%', maxHeight: '90%', overflowY: 'auto', position: 'relative' }} ref={drawerRef}>
        <header className="modal-card-head" style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #dbdbdb', padding: '20px' }}>
          <p className="modal-card-title has-text-black">{selectedVertical.title}</p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: '#ffffff', color: '#000000', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflowY: 'auto' }}>
          <p>{selectedVertical.information}</p>
          {selectedVertical.videoLink && (
            <a href={selectedVertical.videoLink} target="_blank" rel="noopener noreferrer" className="button is-link mt-3" style={{ backgroundColor: '#EE75FF', color: 'white', border: 'none', outline: 'none', alignSelf: 'center' }}>
              Watch Video
            </a>
          )}
        </section>
      </div>
    </div>
  );
};

export default InfoDrawer;
